import axios from 'axios'
import React from 'react'
import ReactModal from 'react-modal'
import { AuthenticationContext } from './AuthenticationContext'
import { withValidation } from '../validation'
import { withRouter } from 'react-router-dom'
import '../../index.css'

ReactModal.setAppElement('#root')

class LoginForm extends React.Component {
    state = {
        values: {
            emailOrAccessName: '',
            passwordOrAccessCode: ''
        },
        isBusy: false,
        wasRedirected: false,
        error: null
    }

    static contextType = AuthenticationContext

    componentDidMount = () => {
        const { state } = this.props.location
        this.setState({
            wasRedirected: state && state.from
        })
    }

    onChange = e => {
        this.props.validation.reset()
        this.setState({
            error: null,
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        })
    }

    onSubmit = async e => {
        e.preventDefault()

        if (!this.props.validation.validate(this.state.values)) {
            return
        }

        this.setState({
            isBusy: true
        })

        try {
            const { data } = await axios.post(
                '/api/users/authenticate',
                this.state.values
            )

            this.context.authenticate(data)

            if (this.props.redirect) {
                const { state } = this.props.location

                if (state && state.from && typeof state.from !== 'string') {
                    const { pathname, search } = state.from
                    const url = pathname + search
                    this.props.history.push(url)
                } else {
                    this.props.history.push('/plus')
                }
            } else {
                this.setState({
                    isBusy: false
                }, () => {
                    if (this.props.onLogin) {
                        this.props.onLogin()
                    }
                })
            }
        } catch(error) {
            this.setState({
                isBusy: false
            })

            if(error.response) {
                const { status } = error.response;

                if(status === 401 || status === 403) {
                    this.setState({
                        error: status
                    })
                    return;
                }
            }

            throw error;
        }
    }

    render() {
        const {
            state: { values, wasRedirected, error },
            onChange,
            onSubmit
        } = this
        
        const { errors } = this.props.validation

        return (
            <React.Fragment>
                {wasRedirected && <div className="container alert alert-warning">
                    El recurso al que intentó acceder está protegido. Por favor Iniciar sesión.
                </div>}

                {error === 401 && <div className="container alert alert-danger">
                    Combinación de usuario y/o correo electrónico no válida.
                </div>}

                <form className="user" onSubmit={onSubmit}>
                    <div className="form-group">
                        <input
                            className={`form-control input-txt ${
                                errors.emailOrAccessName ? 'is-invalid' : ''
                            }`}
                            type="text"
                            name="emailOrAccessName"
                            value={values.emailOrAccessName}
                            placeholder="Correo electrónico o nombre de acceso"
                            onChange={onChange}
                        />
                        {errors.emailOrAccessName && (
                            <div className="invalid-feedback">
                                Ingresa tu correo electrónico
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <input
                            className={`form-control input-txt ${
                                errors.passwordOrAccessCode ? 'is-invalid' : ''
                            }`}
                            type="password"
                            name="passwordOrAccessCode"
                            value={values.passwordOrAccessCode}
                            placeholder="Contraseña o código de acceso"
                            onChange={onChange}
                        />
                        {errors.passwordOrAccessCode && (
                            <div className="invalid-feedback">
                                Elige una contraseña con 6 caracteres o más y que contenga letras y números
                            </div>
                        )}
                    </div>
                    <div className="login-btn-div">
                        <button
                            className="btn login-btn"
                            type="submit"
                            disabled={this.state.isBusy}
                        >
                            Enviar
                        </button>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}

export default withRouter(
    withValidation({
        emailOrAccessName: 'required',
        passwordOrAccessCode: 'required'
    })(LoginForm)
)
